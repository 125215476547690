import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import socketService from "@/services/socket/socket.service"
import EventEmitter from "events";

class CustomJobService  extends EventEmitter {
  constructor() {
    super()
    socketService.on('customJobs:active', async (params) => {
      this.emit('active', params)
    })

    socketService.on('customJobs:progress', async (params) => {
      this.emit('progress', params)
    })

    socketService.on('customJobs:complete', async (params) => {
      this.emit('complete', params)
    })
  }

  async availableJobs() {
    const apiUrl = UrlKeeper.getUrl()
    let response = await axios.get(apiUrl + '/customJobs/availableJobs')

    if (!response.success) throw new Error(response.results)
    else return response.results
  }

  async execute(jobName, data) {
    const apiUrl = UrlKeeper.getUrl()
    let response = await axios.post(apiUrl + '/customJobs/execute', {
      name: jobName,
      data: data
    })

    if(!response.success) throw new Error(response.results)
    else return response.results
  }
}

export default new CustomJobService();
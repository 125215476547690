<template>
  <div>
    <TitleWithBackButton title="" back-route="Settings" icon="mdi-graphql"></TitleWithBackButton>
    <v-container>
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="pb-0">{{
              $translations.t("settings.emulateRtPrinter.title")
            }}</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                {{ $translations.t("settings.emulateRtPrinter.description") }}
              </div>
              <v-switch
                class="pa-0 ma-0"
                hide-details="auto"
                v-model="emulateRtPrinter"
                @change="setSetting('emulateRtPrinter', $event)"
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="pb-0">Carica servizi</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Seleziona il file dei servizi di default per iniziare ad usare
                l'applicazione
                <v-btn color="primary" @click="openServiceUploadDialog">
                  Carica servizi
                </v-btn>
              </div>
              <StandardDialog
                v-model="serviceUploadDialog"
                :dialog-height="null"
                title="Carica Servizi"
                dialog-max-width="1000px"
              >
                <FileUploader
                  class="mt-3"
                  @input="handleFileSelection"
                  extensionCheck="csv"
                  @error-extension="handleErrorExtension"
                ></FileUploader>
                <template v-slot:footer-actions>
                  <v-btn
                    class="right"
                    color="primary"
                    :disabled="canContinue || loadingFile"
                    @click="elaborate('services')"
                  >
                      <v-progress-circular
                      v-if="loadingFile"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <div v-else>
                      Importa
                    </div>
                  </v-btn>
                </template>
              </StandardDialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="pb-0">Carica operatori</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Seleziona il file degli operatori di default per iniziare ad usare
                l'applicazione
                <v-btn color="primary" @click="openOperatorUploadDialog">
                  Carica operatori
                </v-btn>
              </div>
              <StandardDialog
                v-model="operatorUploadDialog"
                :dialog-height="null"
                title="Carica Operatori"
                dialog-max-width="1000px"
              >
                <FileUploader
                  class="mt-3"
                  @input="handleFileSelection"
                  extensionCheck="csv"
                  @error-extension="handleErrorExtension"
                ></FileUploader>
                <template v-slot:footer-actions>
                  <v-btn
                    class="right"
                    color="primary"
                    :disabled="canContinue || loadingFile"
                    @click="elaborate('operators')"
                  >
                    <v-progress-circular
                      v-if="loadingFile"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <div v-else>
                      Importa
                    </div>
                  </v-btn>
                </template>
              </StandardDialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="pb-0">Carica clienti</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Seleziona il file dei clienti di default per iniziare ad usare
                l'applicazione
                <v-btn color="primary" @click="openCustomerUploadDialog">
                  Carica clienti
                </v-btn>
              </div>
              <StandardDialog
                v-model="customerUploadDialog"
                :dialog-height="null"
                title="Carica Clienti"
                dialog-max-width="1000px"
              >
                <FileUploader
                  class="mt-3"
                  @input="handleFileSelection"
                  extensionCheck="csv"
                  @error-extension="handleErrorExtension"
                ></FileUploader>
                <template v-slot:footer-actions>
                  <v-btn
                    class="right"
                    color="primary"
                    :disabled="canContinue || loadingFile "
                    @click="elaborate('customers')"
                  >
                    <v-progress-circular
                      v-if="loadingFile"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <div v-else>
                      Importa
                    </div>
                  </v-btn>
                </template>
              </StandardDialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="pb-0">Carica agenda</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Seleziona il file dell'agenda di default per iniziare ad usare
                l'applicazione
                <v-btn color="primary" @click="openCalendarUploadDialog">
                  Carica agenda
                </v-btn>
              </div>
              <StandardDialog
                v-model="calendarUploadDialog"
                :dialog-height="null"
                title="Carica agenda"
                dialog-max-width="1000px"
              >
                <FileUploader
                  class="mt-3"
                  @input="handleFileSelection"
                  extensionCheck="csv"
                  @error-extension="handleErrorExtension"
                ></FileUploader>
                <template v-slot:footer-actions>
                  <v-btn
                    class="right"
                    color="primary"
                    :disabled="canContinue || loadingFile "
                    @click="elaborate('calendar')"
                  >
                    <v-progress-circular
                      v-if="loadingFile"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <div v-else>
                      Importa
                    </div>
                  </v-btn>
                </template>
              </StandardDialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="pb-0">Carica prima nota</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Seleziona il file della prima nota di cassa di default per iniziare ad usare
                l'applicazione
                <v-btn color="primary" @click="openPaymentsUploadDialog">
                  Carica prima nota
                </v-btn>
              </div>
              <StandardDialog
                v-model="paymentsUploadDialog"
                :dialog-height="null"
                title="Carica prima nota"
                dialog-max-width="1000px"
              >
                <FileUploader
                  class="mt-3"
                  @input="handleFileSelection"
                  extensionCheck="csv"
                  @error-extension="handleErrorExtension"
                ></FileUploader>
                <template v-slot:footer-actions>
                  <v-btn
                    class="right"
                    color="primary"
                    :disabled="canContinue || loadingFile "
                    @click="elaborate('payments')"
                  >
                    <v-progress-circular
                      v-if="loadingFile"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <div v-else>
                      Importa
                    </div>
                  </v-btn>
                </template>
              </StandardDialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="pb-0">Carica scadenziario</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Seleziona il file dello Scadenziario di default per iniziare ad usare
                l'applicazione
                <v-btn color="primary" @click="openInvoicesUploadDialog">
                  Carica scadenziario
                </v-btn>
              </div>
              <StandardDialog
                v-model="invoicesUploadDialog"
                :dialog-height="null"
                title="Carica scadenziario"
                dialog-max-width="1000px"
              >
                <FileUploader
                  class="mt-3"
                  @input="handleFileSelection"
                  extensionCheck="csv"
                  @error-extension="handleErrorExtension"
                ></FileUploader>
                <template v-slot:footer-actions>
                  <v-btn
                    class="right"
                    color="primary"
                    :disabled="canContinue || loadingFile "
                    @click="elaborate('invoices')"
                  >
                    <v-progress-circular
                      v-if="loadingFile"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <div v-else>
                      Importa
                    </div>
                  </v-btn>
                </template>
              </StandardDialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title class="pb-0">Elimina cache analitica</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Elimina la cache con i dati degli anni precedenti dell'analitica
                Confronta Periodi Commerciale 
                <v-btn color="primary" @click="deleteCacheBalance">
                  Elimina
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="pb-0">Carica preset</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Seleziona il tipo di preset da importare
                <v-select
                v-model="selectedPreset"
                filled
                :items="presetType"
                item-text="name"
                item-value="value"
                >

                </v-select>
                <v-btn color="primary" :disabled="selectedPreset==undefined" :loading="loadingPreset" @click="preset">
                  Carica preset
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="pb-0">Esegui operazione</v-card-title>
            <v-card-text class="text-left">
              <div class="pb-2">
                Selezione l'operazione da eseguire
              </div>
              <v-autocomplete
                v-model="operationToExecute"
                filled
                :items="availableOperations"
                clearable
              ></v-autocomplete>
              <v-btn 
                class="mt-2"
                color="primary"
                :disabled="!operationToExecute"
                @click="executeCustomJob(operationToExecute)"
              >
                Esegui
              </v-btn>
              <div class="mt-10">
                <div class="text-h6">
                  Running Jobs
                </div>
                <div v-for="(job, jobId) in runningJobs" :key="jobId">
                  <div>
                    jobId: {{jobId}}
                    <span v-if="job.progress == 100">(completed)</span>
                  </div>
                  <v-progress-linear
                    class="mt-2"
                    :value="job.progress"
                  ></v-progress-linear>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <StandardDialog
        v-model="showFileExtensionDialog"
        dialog-width="400"
        dialog-height="calc(360-1)"
        :title="titleMessage"
      >
        {{ errorMessage }}
      </StandardDialog>
    </v-container>
  </div>
</template>

<script>
import FileUploader from "@/components/common/FileUploader.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import importerService from "@/services/importer/importer.service";
import importVocabulary from "@/vocabulary/importer/import.vocabulary.js";
import customJobService from '@/services/customJob.service';
import balanceService from '@/services/balance/balance.service.js'

export default {
  name: "DevelopersSettings",
  components: {
    FileUploader,
    StandardDialog,
    TitleWithBackButton
  },
  data: function () {
    return {
      emulateRtPrinter: undefined,
      serviceUploadDialog: false,
      operatorUploadDialog: false,
      customerUploadDialog: false,
      calendarUploadDialog: false,
      paymentsUploadDialog: false,
      invoicesUploadDialog: false,
      fileToImport: undefined,
      showFileExtensionDialog: false,
      errorMessage: undefined,
      titleMessage: "Errore Inserimento File",
      entityToImport: [],
      loadingFile: false,
      presetType: [
        { value: 'franchising', name: 'Franchising' },
        { value: 'newCustomer', name: 'New Customer (Parrucchiere + Estetica)' },
        { value: 'newCustomerBeauty', name: 'New Customer (Estetica)' },
        { value: 'newCustomerBarber', name: 'New Customer (Parrucchiere)' },
        { value: 'existingCustomerOnlyBeauty', name: 'Existing Customer (Estetica)' }
      ],
      selectedPreset: undefined,
      loadingPreset: false,
      operationToExecute: undefined,
      availableOperations: [],
      runningJobs: {}
    };
  },
  mounted: function() {
    this.loadAvailableOperations()
    
    let self = this
    customJobService.on('active', (params) => {
      self.runningJobs[params.job.id] = params.job
      self.$forceUpdate()
    })

    customJobService.on('progress', (params) => {
      self.runningJobs[params.job.id] = params.job
      self.$forceUpdate()
    })

    customJobService.on('complete', (params) => {
      self.runningJobs[params.job.id] = params.job
      self.$forceUpdate()
    })
  },
  methods: {
    setSetting: function (key, value) {
      this.$settings.set(key, value);
    },
    openServiceUploadDialog() {
      this.serviceUploadDialog = true;
    },
    openOperatorUploadDialog() {
      this.operatorUploadDialog = true;
    },
    openCustomerUploadDialog() {
      this.customerUploadDialog = true;
    },
    openCalendarUploadDialog() {
      this.calendarUploadDialog = true;
    },
    openPaymentsUploadDialog() {
      this.paymentsUploadDialog = true;
    },
    openInvoicesUploadDialog() {
      this.invoicesUploadDialog = true;
    },
    handleFileSelection(file) {
      this.fileToImport = file;
    },
    elaborate(entity) {
      return new Promise(async (resolve, reject) => {
        this.loadingFile = true;
        try {
          let entitiesElaborated = await importerService.uploadFromFile(
            this.fileToImport,
            entity,
            this.userId
          );
          this.showFileExtensionDialog = true;

          switch(entity) {
            case 'services':
              this.serviceUploadDialog = false;
              this.titleMessage = importVocabulary.SuccessfullyImport.title;
              this.errorMessage = importVocabulary.SuccessfullyImport.desc(
                entitiesElaborated.rowsImported.length,
                entitiesElaborated.rowsInError.length
              );
              break;
            case 'operators':
              this.operatorUploadDialog = false;
              this.titleMessage = importVocabulary.SuccessfullyImportOperators.title;
              this.errorMessage = importVocabulary.SuccessfullyImportOperators.desc(
                entitiesElaborated.rowsImported.length,
                entitiesElaborated.rowsInError.length
              );
              break;
            case 'customers':
              this.customerUploadDialog = false;
              this.titleMessage = importVocabulary.SuccessfullyImportCustomers.title;
              this.errorMessage = importVocabulary.SuccessfullyImportCustomers.desc(
                entitiesElaborated.rowsImported.length,
                entitiesElaborated.rowsInError.length
              );
              break;
            case 'calendar':
              this.calendarUploadDialog = false;
              this.titleMessage = importVocabulary.SuccessfullyImportCalendar.title;
              this.errorMessage = importVocabulary.SuccessfullyImportCalendar.desc(
                entitiesElaborated.rowsImported.length,
                entitiesElaborated.rowsInError.length
              );
              break;
            case 'payments':
              this.titleMessage = importVocabulary.SuccessfullyImportPayments.title;
              this.errorMessage = importVocabulary.SuccessfullyImportPayments.desc(
                entitiesElaborated.rowsImported.length,
                entitiesElaborated.rowsInError.length
              );
              break;
            case 'invoices':
              this.titleMessage = importVocabulary.SuccessfullyImportInvoices.title;
              this.errorMessage = importVocabulary.SuccessfullyImportInvoices.desc(
                entitiesElaborated.rowsImported.length,
                entitiesElaborated.rowsInError.length
              );
              break;
            default:
              return;
          }
          
          this.fileToImport = undefined;
          this.entityToImport = [];
          this.loadingFile = false;
          resolve();
        } catch (err) {
          this.titleMessage = importVocabulary.ErrorImport.title;
          this.showFileExtensionDialog = true;
          switch(entity) {
            case 'services':
              this.serviceUploadDialog = false;
              break;
            case 'operators':
              this.operatorUploadDialog = false;
              break;
            case 'customers':
              this.customerUploadDialog = false;
              break;
            case 'calendar':
              this.calendarUploadDialog = false;
              break;
            case 'payments':
              this.paymentsUploadDialog = false;
              break;
            case 'invoices':
              this.invoicesUploadDialog = false;
              break;
            default:
              return;
          }
          this.fileToImport = undefined;
          this.errorMessage = importVocabulary.ErrorImport.desc(err);
          this.entityToImport = [];
          this.loadingFile = false;

          reject(err);
        }
      });
    },
    loadAvailableOperations() {
      customJobService.availableJobs().then((response) => {
        this.availableOperations = response
      })
    },
    handleErrorExtension() {
      this.titleMessage = "Errore Inserimento File";
      this.showFileExtensionDialog = true;
      this.errorMessage = importVocabulary.FileExtensionNotCorrectCsv.desc;
    },
    resetAfterElaborate() {
      this.fileToImport = undefined;
      this.serviceUploadDialog = false;
    },
    async preset(){
      this.loadingPreset = true;
      await importerService.preset(this.selectedPreset).then(response => {
        if(response)
          this.$delegates.snackbar('Importazione riuscita');
        else
          this.$delegates.snackbar('Importazione fallita');
        this.loadingPreset = false;
      });
    },
    async executeCustomJob(name, data) {
      let confirmed = confirm(`Vuoi davvero eseguire il job '${name}'?`)
      if(confirmed) {
        await customJobService.execute(name, data)
      }
    },
    deleteCacheBalance() {
      balanceService.deleteCacheBalance().then((result) => {
        if (result) 
          this.$delegates.snackbar('Eliminazione riuscita');
        else
          this.$delegates.snackbar('Eliminazione fallita');
      })
    }
  },
  computed: {
    canContinue() {
      return this.fileToImport === undefined ? true : false;
    },
  },
  settings: {
    emulateRtPrinter: {
      bind: "emulateRtPrinter",
    },
  },
};
</script>

<style>
</style>